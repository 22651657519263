import jwtDecode from 'jwt-decode';

import { ClaimSet, getApplicationRoles, Role } from '@sigfig/digital-wealth-core';

export const isSelectFinancialAdvisorApplicable = (jwt: string | undefined): boolean => {
  if (jwt) {
    const decodedJwt = jwtDecode(jwt) as ClaimSet;
    const roles = getApplicationRoles('DIGITAL_WEALTH', decodedJwt);
    return roles.some((role: Role) => role.rln === 'SalesAssistant' || role.rln === 'AssistantAdvisor');
  }

  return false;
};
