import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom-v5-compat';
import SelectFinancialAdvisor from 'src/App/SelectFinancialAdvisor';

import {
  ApolloProvider,
  cacheTypePolicies as symphonyCacheTypePolicies,
  ClaimSet,
  ErrorComponent,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  HttpLink,
  InMemoryCache,
  LoadingPage,
  setContext,
  sfPosthog,
  useCoreConfig,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { routes } from '../../App/routes';
import config, { PartnerConfig } from '../../config';
import { getSymphonyPath } from '../../utils';
import { isSelectFinancialAdvisorApplicable } from '../../utils/auth';
import { DocusignUserType } from '../../utils/docusign';
import { ProtectedRoute } from '..';
import { KeepAlive } from '../KeepAlive';

export interface AuthOutletContext {
  assistantFaPartyId: string | null;
  faPartyId: string;
  jwt: string;
  setFaPartyId: (partyId?: string) => void;
  setShowFinancialAdvisorModal: (toggle: boolean) => void;
  showFinancialAdvisorModal: boolean;
  viewerPartyId: string;
}

const AuthedApp = ({ authenticationOptional }: { authenticationOptional: boolean }) => {
  const posthog = usePostHog();
  const location = useLocation();
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const [searchParams] = useSearchParams();
  const [jwt, setJwt] = useState('');
  const [assistantFaPartyId, setAssistantFaPartyId] = useState<string | null>(null);
  const [faPartyId, setFaPartyId] = useState('');
  const [viewerPartyId, setViewerPartyId] = useState('');
  const [showFinancialAdvisorModal, setShowFinancialAdvisorModal] = useState(false);
  const namespace = 'https://fc.sigfig.com';
  const advisorId = user?.[`${namespace}:advisorId`];
  sfPosthog.useCaptureSpaPageViews(useLocation());

  useEffect(() => {
    if (user) {
      const frontEndJwt = user[`${namespace}:frontEndJwt`];
      setJwt(user[`${namespace}:frontEndJwt`]);

      const decodedJwt = jwtDecode(frontEndJwt) as ClaimSet;
      const inContextPartyId = decodedJwt.sub ?? '';
      const userType = searchParams.get('userType');
      const isUserTypeFA = userType === DocusignUserType.FA;
      if (isSelectFinancialAdvisorApplicable(frontEndJwt) && !faPartyId) {
        if (isUserTypeFA && location.pathname.includes('docusignCompleteAuth')) {
          setAssistantFaPartyId(inContextPartyId);
          setViewerPartyId(inContextPartyId);
          posthog?.identify(inContextPartyId);
          return;
        }
        setShowFinancialAdvisorModal(true);
        setAssistantFaPartyId(inContextPartyId);
      } else if (!faPartyId) {
        setFaPartyId(inContextPartyId);
      }
      setViewerPartyId(inContextPartyId);
      posthog?.identify(inContextPartyId);
    }
  }, [posthog, user]);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
  }, [jwt]);

  const symphonyAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      (networkError as any)?.result?.status === 403 &&
      (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      posthog?.reset();
      logout({ returnTo: config.auth0.logoutUrl });
    }
  });

  // TODO: Fix apollo client parameters
  // https://jira.sigfig.com/browse/DA2-696
  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: from([
      errorLink,
      symphonyAuthLink.concat(
        new HttpLink({
          uri,
          fetch,
          headers:
            process.env.NODE_ENV === 'development'
              ? {
                  connection: 'keep-alive',
                }
              : undefined,
        }),
      ),
    ]),
  });
  const symphonyUrl = `${getSymphonyPath()}/ui/graphql`;

  const apolloClient = getApolloClient({
    links: [
      getContentstackHttpLinkConfig(
        config.contentstack.environment as string,
        config.contentstack.deliveryToken as string,
      ),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
        FinancialAccount: {
          // use FinancialAccount.id as unique id, but fallback to ManagedProduct.id if exists else the combination of (acc. no. + attributes.NYL.VENDOR_CODE)
          keyFields: storeObject => {
            const financialAccount = storeObject as {
              __typename: string;
              accountNumber: string;
              attributes?: { name: string; value: string }[];
              id?: string;
              products?: Array<{ __typename: 'ManagedProduct' | string; id: string }>;
              routingNumber?: string;
            };
            const { accountNumber, routingNumber, attributes } = financialAccount;
            const id =
              financialAccount.id ??
              financialAccount.products?.find(product => product.__typename === 'ManagedProduct')?.id ??
              (accountNumber && routingNumber
                ? `${routingNumber}-${accountNumber}`
                : `${accountNumber + attributes?.find(att => att.name === 'NYL.VENDOR_CODE')?.value}`);
            return `${financialAccount.__typename}:${id}`;
          },
        },
      },
    }),
  });

  const outletContext = useMemo(
    () => ({
      assistantFaPartyId,
      faPartyId,
      jwt,
      setFaPartyId,
      setShowFinancialAdvisorModal,
      showFinancialAdvisorModal,
      viewerPartyId,
    }),
    [
      assistantFaPartyId,
      faPartyId,
      jwt,
      setFaPartyId,
      setShowFinancialAdvisorModal,
      showFinancialAdvisorModal,
      viewerPartyId,
    ],
  );

  return (
    <ApolloProvider client={apolloClient}>
      {isLoading || (isAuthenticated && jwt === '') ? (
        <LoadingPage />
      ) : error ? (
        <ErrorComponent contentOptions={contentOptions} isAuthorizationError />
      ) : authenticationOptional ? (
        <Outlet context={outletContext} />
      ) : (
        <ProtectedRoute
          component={() => (
            <KeepAlive redirectUrl={routes.faDashboard(faPartyId !== '' ? faPartyId : assistantFaPartyId ?? '')}>
              {showFinancialAdvisorModal ? (
                <SelectFinancialAdvisor
                  assistantFaPartyId={assistantFaPartyId}
                  externalPartyId={advisorId}
                  setFaPartyId={partyId => setFaPartyId(partyId)}
                  setShowFinancialAdvisorModal={setShowFinancialAdvisorModal}
                />
              ) : (
                <Outlet context={outletContext} />
              )}
            </KeepAlive>
          )}
        />
      )}
    </ApolloProvider>
  );
};

export default AuthedApp;
