export { default as BoldFont } from './fonts/tahoma-bold.ttf';
export { default as NormalFont } from './fonts/tahoma.ttf';
import { alpha } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

import { createDefaultTheme, SfTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

export enum FontFamilies {
  Georgia = 'Georgia',
  Tahoma = 'Tahoma',
  TahomaBold = 'Tahoma Bold',
}

export type NylTheme = SfTheme;

export type NylThemeOptions = Partial<NylTheme>;

const palette = {
  action: {
    background: '#EBF7F5',
    disabled: '#868B8D',
    disabledBackground: '#E9E8E8',
  },
  error: { main: '#CC0000', dark: '#621B16' },
  info: { main: '#1BB3BC' },
  primary: {
    dark: '#040F24',
    main: '#0C3C52',
    white: '#FFFFFF',
    light: 'rgba(12, 60, 82, 0.08)',
  },
  secondary: { main: '#0C3C52' },
  success: { main: '#63BA68', light: '#EDF7ED', dark: 'rgba(30, 70, 32, 1)' },
  text: {
    blue: '#0B79D0',
    primary: '#434343',
    secondary: '#666666',
    disabled: '#BFBFBF',
  },
  warning: { main: '#FF9800', background: '#FFF5E5', textDark: 'rgba(102, 61, 0, 1)' },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
};

const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '&.MuiAlert-standardError': {
          backgroundColor: '#FEECEB',
          color: '#621B16',
        },
      },
      standardWarning: {
        backgroundColor: '#FFF5E5',
        color: '#663D00',
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        '&.MuiAlert-standardError': {
          backgroundColor: '#FEECEB',
          color: '#621B16',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: false,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        fontSize: '16px',
      },
      sizeMedium: {
        fontSize: '14px',
      },
      sizeSmall: {
        fontSize: '14px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        color: 'text.primary',
        '.MenuItem-label': {
          marginBottom: '-2.5px',
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        color: palette.primary.main,
        '&.MuiToggleButton-root': {
          borderColor: alpha(palette.primary.main, 0.5),
        },
        '&.Mui-selected': {
          backgroundColor: palette.action.background,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#BFBFBF',
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#666666',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#BFBFBF',
        },
      },
    },
  },
};

const breakpoints = createBreakpoints({});
const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 36,
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '60px',
  },
  h2: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 26,
    fontWeight: 400,
    lineHeight: '60px',
    [breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '42px',
    },
  },
  h3: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '44px',
  },
  h4: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '40px',
  },
  h5: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '34px',
  },
  h6: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '32px',
  },
  subtitle1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  subtitle2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  body1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: 700,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  button: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  caption: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  overline: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '28px',
  },
};

const sfComponents: NylThemeOptions = {
  sfAccountDetails: {
    styles: {
      dropdownLabel: {
        '.MenuItem-label': {
          overflow: 'visible !important',
        },
      },
    },
  },
  sfAccountPerformance: {
    styles: {
      content: {
        background: palette.background.default,
      },
    },
    typographyVariants: {
      accountNumber: 'body2',
      balanceValue: 'h2',
    },
  },
  sfDownloadProposal: {
    styles: {
      downloadButton: {
        fontSize: 16,
        fontWeight: 16,
        lineHeight: '24px',
        textDecoration: 'underline',
      },
    },
  },
  sfDropdown: {
    root: {
      '.MuiBox-root': {
        marginBottom: '0.25px',
      },
    },
    styles: {
      outlinedClassic: {
        inputLabel: {
          ml: 0,
          mb: 0,
        },
      },
    },
  },
  sfMadLibs: {
    root: {
      '.MuiInput-input': {
        color: 'primary.main',
      },
    },
    styles: {
      disclosureColor: 'text.primary',
      headingColor: 'primary.main',
      secondaryInputColor: 'text.secondary',
    },
    typographyVariants: {
      heading: 'h3',
      input: 'h3',
      dropdown: 'h3',
    },
  },
  sfOnboardingPageHeader: {
    styles: {
      container: { my: 2, textAlign: 'center' },
      icon: { my: 2, width: 100 },
      title: { color: 'primary.main', my: 2 },
    },
    typographyVariants: { title: 'h3' },
  },
  sfPaperwork: {
    styles: {
      maxDropdownHeight: '380px',
      sectionCTA: typography.body1,
    },
  },
  sfPerformanceChart: {
    styles: {
      linkDecoration: 'none',
      lineColor: palette.primary.main,
    },
  },
  sfPortfolioCompare: {
    styles: {
      linearProgress: {
        backgroundColor: '#FAFAFA',
        height: '8px',
      },
      donut: {
        backgroundColor: '#E0E0E0',
        color: palette.primary.main,
      },
    },
  },
  sfQuestionnaire: {
    styles: {
      card: {
        background: 'linear-gradient(0deg, rgba(12, 60, 82, 0.08), rgba(12, 60, 82, 0.08)), #FFFFFF',
        borderColor: '#CED8DC',
        minHeight: 300,
      },
      maxDropdownHeight: '380px',
    },
  },
  sfReturnsChart: {
    styles: {
      colors: {
        default: palette.primary.main,
        BBG000BZZS63: '#4CAF50',
        BBG000TH7DF8: '#B71C1C',
      },
    },
  },
  sfFunding: {
    root: {
      '.MuiInputLabel-root': {
        mb: 0,
        ml: 0,
      },
      '.MuiInputBase-input.Mui-disabled': {
        backgroundColor: '#EEEEEE',
        WebkitTextFillColor: '#666666',
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#0000003B !important',
      },
      '.MuiButtonBase-root.Mui-disabled': {
        backgroundColor: '#EEEEEE',
        WebkitTextFillColor: '#666666',
      },
      '.MuiInputLabel-root.Mui-disabled': {
        color: 'text.primary',
      },
    },
    styles: {},
  },
  sfPlan: {
    styles: {
      portfolioCompareStatusChip: {
        label: {
          recommended: { backgroundColor: 'primary.light', color: 'primary.dark' },
          required: { backgroundColor: 'warning.background', color: 'warning.textDark' },
          completed: { backgroundColor: 'success.light', color: 'success.dark' },
        },
      },
    },
    root: {
      '[data-qa="plan-header"]': {
        backgroundSize: 'auto',
        backgroundPosition: 'right',
      },
    },
  },
  sfPlayback: {
    root: {
      '[data-qa="playback-details-plan-section"]': {
        '[data-qa="playback-details-plan-section-multiRowFields"]': {
          '>div[data-qa*="playback-details-plan-section-multiRowFields-field-"]:nth-child(even):not(:last-child)': {
            position: 'relative',
            paddingBottom: '16px',
            '::after': {
              content: '""',
              backgroundColor: 'primary.light',
              position: 'absolute',
              height: '1px',
              left: '16px',
              right: '0',
              bottom: '0',
            },
          },
        },
      },
    },
    styles: {},
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
  ...sfComponents,
});
