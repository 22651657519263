import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useSymphonyQuery } from '@sigfig/digital-wealth-core';

import { GetAdvisorPartyId, GetAdvisorPartyIdVariables } from './__generated__/GetAdvisorPartyId';

const getAdvisorPartyIdQuery = loader('./getAdvisorPartyId.gql');

export const useGetAdvisorPartyId = (
  options?: QueryHookOptions<GetAdvisorPartyId, GetAdvisorPartyIdVariables>,
): QueryResult<GetAdvisorPartyId, GetAdvisorPartyIdVariables> => useSymphonyQuery(getAdvisorPartyIdQuery, options);
