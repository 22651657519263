import { AlertColor } from '@mui/material';
import { getYear } from 'date-fns';
import React, { ReactNode } from 'react';

import {
  FinancialAccountSource,
  FinancialAccountType,
  FundingSources,
  getDefaultMarketValue,
  HelperTextContent,
  PortfolioAccount,
} from '@sigfig/digital-wealth-core';

import { AccountType, FundingSource } from '../contentstack';
import { BrokerageFinancialAccount, BrokeragePositionV2, SelectedBrokerageAccount } from '../symphony';
import { GetBrokerageAccountBalances_client_financialAccounts } from '../symphony/__generated__/GetBrokerageAccountBalances';

export const TAX_SHELTERED_ACCOUNT_TYPES = [
  FinancialAccountType.ROTH_IRA,
  FinancialAccountType.TRADITIONAL_IRA,
  FinancialAccountType.ROLLOVER_IRA,
];

export const NON_TAX_SHELTERED_ACCOUNT_TYPES = [
  FinancialAccountType.INDIVIDUAL,
  FinancialAccountType.JOINT,
  FinancialAccountType.JOINT_TENANTS_IN_COMMON,
];

export const IRA_ACCOUNT_TYPES = [
  FinancialAccountType.ROLLOVER_IRA,
  FinancialAccountType.ROTH_IRA,
  FinancialAccountType.TRADITIONAL_IRA,
];

export const CUSTODIAL_IRA_ACCOUNT_TYPES = [
  FinancialAccountType.CUSTODIAL_ROLLOVER_IRA,
  FinancialAccountType.CUSTODIAL_ROTH_IRA,
  FinancialAccountType.CUSTODIAL_TRADITIONAL_IRA,
];

export const JOINT_ACCOUNT_TYPES = [FinancialAccountType.JOINT, FinancialAccountType.JOINT_TENANTS_IN_COMMON];

export const UN_CHANGABLE_ACCOUNT_TYPES = [FinancialAccountType.UTMA];

export const PROPOSAL_ID_MAX_LENGTH = 33;

export const BRINKER_ACCOUNT_VENDOR_CODE = 'BRI';

export const PROSPER_ACCOUNT_VENDOR_CODE = 'L11';

export const isIraAccount = (accountType?: FinancialAccountType): boolean =>
  !!accountType && IRA_ACCOUNT_TYPES.includes(accountType);

export const isCustodialIraAccount = (accountType?: FinancialAccountType): boolean =>
  !!accountType && CUSTODIAL_IRA_ACCOUNT_TYPES.includes(accountType);

export const filterAccountTypes: (
  accountTypes: AccountType[],
  isTaxSheltered: boolean,
  selectedfundingAccountType?: FinancialAccountType | undefined,
) => AccountType[] = (accountTypes, isTaxSheltered, selectedfundingAccountType) => {
  if (selectedfundingAccountType && isIraAccount(selectedfundingAccountType)) {
    return accountTypes.filter(account => IRA_ACCOUNT_TYPES.includes(account.key as FinancialAccountType));
  }
  if (selectedfundingAccountType && isCustodialIraAccount(selectedfundingAccountType)) {
    return accountTypes.filter(account => CUSTODIAL_IRA_ACCOUNT_TYPES.includes(account.key as FinancialAccountType));
  }
  if (selectedfundingAccountType && UN_CHANGABLE_ACCOUNT_TYPES.includes(selectedfundingAccountType)) {
    return accountTypes.filter(account => account.key === selectedfundingAccountType);
  }
  return accountTypes.filter(
    account =>
      (isTaxSheltered && TAX_SHELTERED_ACCOUNT_TYPES.indexOf(account.key as FinancialAccountType) !== -1) ||
      (!isTaxSheltered && NON_TAX_SHELTERED_ACCOUNT_TYPES.indexOf(account.key as FinancialAccountType) !== -1),
  );
};

export const filterFundingSources: (isTaxSheltered: boolean, fundingSources: FundingSource[]) => FundingSource[] = (
  isTaxSheltered,
  fundingSources,
) => {
  if (isTaxSheltered) {
    return fundingSources.filter(source => source.data_value !== FundingSources.otherBrokerageAccount);
  }
  return fundingSources;
};

export const isInvestmentAmountGreater = (investmentAmount: number, actualInvestmentAmount?: number): boolean => {
  return !!actualInvestmentAmount && investmentAmount > actualInvestmentAmount * 1.05;
};

export const isInvestmentAmountLesser = (investmentAmount: number, actualInvestmentAmount?: number): boolean => {
  return !!actualInvestmentAmount && investmentAmount < actualInvestmentAmount * 0.95;
};

export const checkValidInvestmentAmount = (investmentAmount: number, actualInvestmentAmount: number): boolean =>
  !(
    isInvestmentAmountLesser(investmentAmount, actualInvestmentAmount) ||
    isInvestmentAmountGreater(investmentAmount, actualInvestmentAmount)
  );

export const checkValidSourceBankAccount = (source: string, bankAccount?: string): boolean =>
  source !== FundingSources.bankAccount || !!bankAccount;

export const checkValidRecurringSourceBankAccount = (
  source: string,
  skipRecurring: boolean,
  recurringSourceBankAccount?: string,
  isEmployerSponsoredRollover?: boolean,
): boolean =>
  (source === FundingSources.fundLater && !isEmployerSponsoredRollover) ||
  skipRecurring ||
  !!recurringSourceBankAccount;

export const isTaxSheltered = (accountType: FinancialAccountType): boolean =>
  [FinancialAccountType.ROTH_IRA, FinancialAccountType.TRADITIONAL_IRA].includes(accountType);

export const isBeforeTaxFilingDeadline = (): boolean => {
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const taxFilingEndDate = new Date(`04/15/${currentYear}`);
  return currentDate <= taxFilingEndDate;
};

export const getIsContributionYearVisible = (
  accountType: FinancialAccountType,
  source: FundingSources,
  sourceBankAccount: string,
  is60DaysRollover = false,
) =>
  !is60DaysRollover &&
  source === FundingSources.bankAccount &&
  sourceBankAccount &&
  isBeforeTaxFilingDeadline() &&
  isTaxSheltered(accountType);

export const getBrokerageAccountLabel = (account: BrokerageFinancialAccount): string =>
  `${account.financialInstitution ?? 'Account'} ${
    account.maskedAccountNumber ? `${account.maskedAccountNumber} ` : ''
  }`;

export const getPositionV2AndCashTotal = (selectedPositionV2?: BrokeragePositionV2[], cashValue?: string): number => {
  return (
    (selectedPositionV2?.reduce(
      (acc, curr) =>
        acc +
        Number(
          getDefaultMarketValue({
            marketValue: curr.marketValues?.[0]?.value,
            unitPrice: curr.marketPrices?.[0]?.value,
            units: curr.quantity,
          }),
        ),
      0,
    ) || 0) + Number(cashValue)
  );
};

export const isFundingAmountValid = (
  accountType: FinancialAccountType,
  amountValidationBasedOnAccountTypes: {
    accountType: FinancialAccountType;
    ageMidpoint: number;
    currentYear: {
      valueGreaterThanAge: number;
      valueLesserThanAge: number;
    };
    previousYear: {
      valueGreaterThanAge: number;
      valueLesserThanAge: number;
    };
  }[],
  amount: number,
  age: number,
  contributionYear?: number,
): boolean => {
  const amountValidation = amountValidationBasedOnAccountTypes.find(account => account.accountType === accountType);

  if (amountValidation && amount) {
    let amountValidationYear = amountValidation.previousYear;
    if (!contributionYear || contributionYear === getYear(new Date())) {
      amountValidationYear = amountValidation.currentYear;
    }

    if (age >= amountValidation.ageMidpoint) {
      return amount <= amountValidationYear.valueGreaterThanAge;
    }
    return amount <= amountValidationYear.valueLesserThanAge;
  }

  return true;
};

export const getAlert = (message: string | ReactNode, inputId: string, severity: AlertColor) => {
  return (
    <HelperTextContent error id={`${inputId}-helper-text`} severity={severity}>
      {message}
    </HelperTextContent>
  );
};

export const isFundingAccountTypeDisabled = (accountType?: FinancialAccountType): boolean =>
  !!accountType && UN_CHANGABLE_ACCOUNT_TYPES.includes(accountType);

export const isInternalFundingAccount = (
  account?: PortfolioAccount | SelectedBrokerageAccount | GetBrokerageAccountBalances_client_financialAccounts,
): boolean => account?.source === FinancialAccountSource.PARTNER_INTERNAL;

export const isJournalTransfer = (dataSourceCode?: string | null): boolean =>
  !!(dataSourceCode && ['SEC', 'EAG'].includes(dataSourceCode));

// If the Delivering Account = Traditional IRA, Roth IRA, Rollover IRA AND the receiving account = Individual Account, Joint Account, or UTMA, then it is a taxable event
// If the Delivering Account = Traditional IRA and the Receiving account = Roth IRA, then it is a taxable event
export const checkForTaxableTransaction = (
  receivingAccount: FinancialAccountType,
  deliveringAccount: FinancialAccountType,
): boolean =>
  (TAX_SHELTERED_ACCOUNT_TYPES.includes(deliveringAccount) &&
    [...NON_TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.UTMA].includes(receivingAccount)) ||
  (deliveringAccount === FinancialAccountType.TRADITIONAL_IRA && receivingAccount === FinancialAccountType.ROTH_IRA);

export const checkForB99AccountNumber = (accountNumber?: string | null) => {
  return accountNumber?.startsWith('B99');
};

export const checkForInvalidAcctTypeCombo = (
  receivingAccountType: FinancialAccountType,
  deliveringAccountType: FinancialAccountType,
  dataSourceCode?: string | null,
  isInternalFunding?: boolean,
  vendorCode?: string | null,
  isConnectedOrManualB99Account?: boolean,
): boolean => {
  if (isInternalFunding && isJournalTransfer(dataSourceCode)) {
    return checkInternalFundingTransfer(
      receivingAccountType,
      deliveringAccountType,
      isConnectedOrManualB99Account,
      vendorCode,
    );
  } else {
    return checkExternalFundingTransfer(receivingAccountType, deliveringAccountType);
  }
};

function checkInternalFundingTransfer(
  receivingAccountType: FinancialAccountType,
  deliveringAccountType: FinancialAccountType,
  isConnectedOrManualB99Account?: boolean,
  vendorCode?: string | null,
): boolean {
  const isProsperAccountVendorCode = vendorCode === PROSPER_ACCOUNT_VENDOR_CODE;
  return (
    isEstateToTaxSheltered(deliveringAccountType, receivingAccountType) ||
    isRothIraToTraditionalOrRollover(deliveringAccountType, receivingAccountType) ||
    (isProsperAccountVendorCode && isNonTaxShelteredToTaxSheltered(deliveringAccountType, receivingAccountType)) ||
    (isProsperAccountVendorCode &&
      isTaxShelteredToNonTaxShelteredOrUTMA(deliveringAccountType, receivingAccountType)) ||
    (isProsperAccountVendorCode && isUtmaOrUgmaToTaxShelteredOrSepIra(deliveringAccountType, receivingAccountType)) ||
    (isProsperAccountVendorCode &&
      isTraditionalOrRolloverToRothIra(deliveringAccountType, receivingAccountType, isConnectedOrManualB99Account))
  );
}

function checkExternalFundingTransfer(
  receivingAccountType: FinancialAccountType,
  deliveringAccountType: FinancialAccountType,
): boolean {
  return (
    isEstateToAnyAccount(deliveringAccountType, receivingAccountType) ||
    isTrustToTaxShelteredOrUTMA(deliveringAccountType, receivingAccountType) ||
    isUTMAorUGMAToMostAccountTypes(deliveringAccountType, receivingAccountType) ||
    isJointToIndividualOrTaxShelteredOrUTMA(deliveringAccountType, receivingAccountType) ||
    isTaxShelteredToNonTaxShelteredOrUTMA(deliveringAccountType, receivingAccountType) ||
    isTraditionalOrRolloverToRothIra(deliveringAccountType, receivingAccountType) ||
    isRothIraToTraditionalOrRollover(deliveringAccountType, receivingAccountType) ||
    isIndividualToTaxShelteredOrUTMA(deliveringAccountType, receivingAccountType)
  );
}

// Helper functions for specific checks
function isEstateToTaxSheltered(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return delivering === FinancialAccountType.ESTATE && TAX_SHELTERED_ACCOUNT_TYPES.includes(receiving);
}

function isRothIraToTraditionalOrRollover(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return (
    delivering === FinancialAccountType.ROTH_IRA &&
    [FinancialAccountType.ROLLOVER_IRA, FinancialAccountType.TRADITIONAL_IRA].includes(receiving)
  );
}

function isNonTaxShelteredToTaxSheltered(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return TAX_SHELTERED_ACCOUNT_TYPES.includes(receiving) && !TAX_SHELTERED_ACCOUNT_TYPES.includes(delivering);
}

function isUtmaOrUgmaToTaxShelteredOrSepIra(
  delivering: FinancialAccountType,
  receiving: FinancialAccountType,
): boolean {
  return (
    [...TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.SEP_IRA].includes(receiving) &&
    [FinancialAccountType.UTMA, FinancialAccountType.UGMA].includes(delivering)
  );
}

function isTraditionalOrRolloverToRothIra(
  delivering: FinancialAccountType,
  receiving: FinancialAccountType,
  isConnectedOrManualB99Account?: boolean,
): boolean {
  return (
    [FinancialAccountType.ROLLOVER_IRA, FinancialAccountType.TRADITIONAL_IRA].includes(delivering) &&
    receiving === FinancialAccountType.ROTH_IRA &&
    !!isConnectedOrManualB99Account
  );
}

function isEstateToAnyAccount(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return (
    delivering === FinancialAccountType.ESTATE &&
    [...NON_TAX_SHELTERED_ACCOUNT_TYPES, ...TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.UTMA].includes(receiving)
  );
}

function isTrustToTaxShelteredOrUTMA(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return (
    delivering === FinancialAccountType.TRUST &&
    [...TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.UTMA].includes(receiving)
  );
}

function isUTMAorUGMAToMostAccountTypes(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return (
    [FinancialAccountType.UTMA, FinancialAccountType.UGMA].includes(delivering) &&
    [...TAX_SHELTERED_ACCOUNT_TYPES, ...NON_TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.SEP_IRA].includes(
      receiving,
    )
  );
}

function isJointToIndividualOrTaxShelteredOrUTMA(
  delivering: FinancialAccountType,
  receiving: FinancialAccountType,
): boolean {
  return (
    JOINT_ACCOUNT_TYPES.includes(delivering) &&
    [FinancialAccountType.UTMA, ...TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.INDIVIDUAL].includes(receiving)
  );
}

function isTaxShelteredToNonTaxShelteredOrUTMA(
  delivering: FinancialAccountType,
  receiving: FinancialAccountType,
): boolean {
  return (
    TAX_SHELTERED_ACCOUNT_TYPES.includes(delivering) &&
    [...NON_TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.UTMA].includes(receiving)
  );
}

function isIndividualToTaxShelteredOrUTMA(delivering: FinancialAccountType, receiving: FinancialAccountType): boolean {
  return (
    delivering === FinancialAccountType.INDIVIDUAL &&
    [...TAX_SHELTERED_ACCOUNT_TYPES, FinancialAccountType.UTMA].includes(receiving)
  );
}

export const sanitizeSourceAccountType = (accountType?: string | null) =>
  accountType && accountType !== FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE ? accountType : null;

export const isManualNotJournalAccount = (
  brokerageAccount?: SelectedBrokerageAccount,
  dataSourceCode?: string | null,
  totalHoldingsValue?: number,
): boolean =>
  !!(
    brokerageAccount?.isManualEntry &&
    !totalHoldingsValue &&
    !isJournalTransfer(dataSourceCode) &&
    isInternalFundingAccount(brokerageAccount)
  );
