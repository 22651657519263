import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';

import { ClaimSet } from '@sigfig/digital-wealth-core';

import { Intent } from '../../App';
import { routes } from '../../App/routes';
import { isSelectFinancialAdvisorApplicable } from '../../utils/auth';

/*
  Let's keep for now until we define the CORE login flow for a partner
*/
export const RootBypass: any = (RedirectComponent: any) => {
  const namespace = 'https://fc.sigfig.com';
  const { user } = useAuth0();
  const location = useLocation();
  const inContextPartyId = user?.[`${namespace}:inContextPartyId`];
  const managedProductId = user?.[`${namespace}:convertedCopilotPortfolioULID`];
  const intent = user?.[`${namespace}:intent`];
  const jwt = user?.[`${namespace}:frontEndJwt`];
  const isRootPath = location.pathname === '/';
  const decodedJwt = jwt ? (jwtDecode(jwt) as ClaimSet) : null;
  const viewerPartyId = decodedJwt?.sub;

  if (isRootPath && (!isSelectFinancialAdvisorApplicable(jwt) || user?.[`${namespace}:advisorId`])) {
    if (inContextPartyId && intent === Intent.Onboarding) {
      return () => <Navigate replace to={routes.onboarding(inContextPartyId, undefined)} />;
    } else if (inContextPartyId && managedProductId) {
      return () => <Navigate replace to={routes.accountDetails(inContextPartyId, managedProductId)} />;
    } else if (inContextPartyId) {
      return () => <Navigate replace to={routes.accountSummary(inContextPartyId)} />;
    }

    return () => <Navigate replace to={routes.faDashboard(viewerPartyId ?? '')} />;
  }

  // Let them navigate wherever. The site is open to them
  return RedirectComponent;
};
