import { BrokerageFinancialAccount } from 'src/containers/Funding/symphony';

import { ExternalBrokerageFinancialAccount } from '@sigfig/digital-wealth-core';

import { SymphonyAttribute, SymphonyStringAttribute } from './types';

export const getBaseUrl = (location: Location = window.location): string => location.origin;
export const getSymphonyPath = (): string => (process.env.NODE_ENV === 'development' ? '' : '/symphony');
export const isInIframe = (): boolean => window.self !== window.top;

export const isStringAttribute = (attribute: SymphonyAttribute): attribute is SymphonyStringAttribute =>
  attribute.__typename === 'StringAttribute';

export const getPlaidMaskedAccountNumber = <T extends SymphonyStringAttribute>(
  attributes: T[] | null | undefined,
): string | undefined => {
  return attributes?.filter(isStringAttribute)?.find(attr => attr.name === 'PLAID_MASKED_ACCOUNT_NUMBER')?.stringValue;
};

export const sortAccountsByFinancialInstitutionAndMaskedNumber = <
  T extends { attributes?: any; financialInstitution?: string | null; maskedAccountNumber?: string | null },
>(
  accounts: Partial<T>[],
): T[] => {
  return [...accounts].sort((account1, account2) => {
    const financialInstitution1 = account1.financialInstitution ? account1.financialInstitution : 'unknown';
    const financialInstitution2 = account2.financialInstitution ? account2.financialInstitution : 'unknown';
    const plaidMaskedAccountNumber1 = getPlaidMaskedAccountNumber(account1.attributes);
    const plaidMaskedAccountNumber2 = getPlaidMaskedAccountNumber(account2.attributes);
    const maskedAccountNumber1 = plaidMaskedAccountNumber1 ?? account1.maskedAccountNumber ?? 'unknown';
    const lastFourCharsMaskedAccountNumber1 = maskedAccountNumber1.slice(-4);
    const maskedAccountNumber2 = plaidMaskedAccountNumber2 ?? account2.maskedAccountNumber ?? 'unknown';
    const lastFourCharsMaskedAccountNumber2 = maskedAccountNumber2.slice(-4);

    if (financialInstitution1 && financialInstitution2 && financialInstitution1 !== financialInstitution2) {
      if (
        (financialInstitution1[0] === financialInstitution1[0].toLocaleLowerCase() &&
          financialInstitution2[0] === financialInstitution2[0].toLocaleUpperCase()) ||
        (financialInstitution1[0] === financialInstitution1[0].toLocaleUpperCase() &&
          financialInstitution2[0] === financialInstitution2[0].toLocaleLowerCase())
      ) {
        return financialInstitution1.localeCompare(financialInstitution2);
      }

      if (financialInstitution1 < financialInstitution2) {
        return -1;
      }

      return 1;
    } else {
      if (lastFourCharsMaskedAccountNumber1 > lastFourCharsMaskedAccountNumber2) {
        return 1;
      } else if (lastFourCharsMaskedAccountNumber1 < lastFourCharsMaskedAccountNumber2) {
        return -1;
      }
    }

    return 0;
  }) as T[]; // Explicitly cast the sorted array to the correct type
};

export const sortBrokerageAccountsByFinancialInstitutionAndMaskedNumber = (
  accounts: BrokerageFinancialAccount[] | ExternalBrokerageFinancialAccount[],
): BrokerageFinancialAccount[] | ExternalBrokerageFinancialAccount[] => {
  return sortAccountsByFinancialInstitutionAndMaskedNumber(accounts as Partial<BrokerageFinancialAccount>[]);
};

export const isManagedProduct = <P, MP extends { __typename?: string }>(product: P): boolean => {
  return (product as unknown as MP).__typename === 'ManagedProduct';
};

export const hasManagedProduct = <T extends { products?: P[] | null }, P, MP extends { __typename?: string }>(
  financialAccount: T,
): boolean => !!financialAccount.products?.find(product => isManagedProduct<P, MP>(product));
