import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

import { AlertAndLoading, SelectFinancialAdvisorModal, useCoreConfig } from '@sigfig/digital-wealth-core';

import { PartnerConfig } from '../../config';
import { routes } from '../routes';

import { useGetAdvisorPartyId } from './symphony';

interface SelectFinancialAdvisoreProps {
  assistantFaPartyId?: string | null;
  externalPartyId?: string;
  setFaPartyId: (partyId: string) => void;
  setShowFinancialAdvisorModal: (toggle: boolean) => void;
}

const SelectFinancialAdvisorWrapper: React.FC<SelectFinancialAdvisoreProps> = ({
  assistantFaPartyId,
  externalPartyId,
  setShowFinancialAdvisorModal,
  setFaPartyId,
}) => {
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const navigate = useNavigate();

  const {
    data: advisorPartyData,
    error: advisoryPartyDataError,
    loading: advisorPartyDataLoading,
  } = useGetAdvisorPartyId({
    variables: { externalPartyId: externalPartyId ?? '' },
    skip: !externalPartyId,
  });

  const onSelectFA = useCallback(
    (partyId: string, shouldNotRedirectToFaDashboard?: boolean) => {
      setFaPartyId(partyId);
      setShowFinancialAdvisorModal(false);
      if (!shouldNotRedirectToFaDashboard) {
        navigate(routes.faDashboard(partyId));
      }
    },
    [navigate, setFaPartyId, setShowFinancialAdvisorModal],
  );

  useEffect(() => {
    const advisorPartyId = advisorPartyData?.client?.party?.id;
    if (advisorPartyId) {
      setShowFinancialAdvisorModal(false);
      onSelectFA(advisorPartyId, true);
    }
  }, [advisorPartyData, onSelectFA, setShowFinancialAdvisorModal]);

  return advisorPartyDataLoading || advisoryPartyDataError ? (
    <AlertAndLoading
      ariaLabel="Loading Advisor Data"
      contentOptions={contentOptions}
      error={advisoryPartyDataError}
      loading={advisorPartyDataLoading}
    />
  ) : assistantFaPartyId && !advisorPartyData?.client?.party?.id ? (
    <SelectFinancialAdvisorModal
      assistantPartyId={assistantFaPartyId}
      contentOptions={contentOptions}
      onSelectFA={onSelectFA}
    />
  ) : (
    <></>
  );
};

export default SelectFinancialAdvisorWrapper;
