/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import { CoreConfigProvider, createTheme, LoadingPage, ThemeProvider } from '@sigfig/digital-wealth-core';

import AuthedApp from '../auth/AuthedApp';
import { partnerConfigs } from '../config';
import { defaultTheme } from '../theme';

import { PageRoute } from './routes';

export enum AccountActionRoutes {
  ACCOUNT_DETAILS = 'AccountDetails',
  ACCOUNT_SUMMARY = 'AccountSummary',
}

export enum Intent {
  Dashboard = 'dashboard',
  Onboarding = 'onboarding',
}

const AccountProfileWrapper = lazy(() => import('../components/AccountProfile'));
const AccountProfileDocusignCompleted = lazy(() => import('../components/AccountProfileDocusignCompleted'));
const AssetClassesDefinitionWrapper = lazy(() => import('../components/AssetClassesDefinitionsWrapper'));
const DigitalWealth = lazy(() => import('../components/DigitalWealth'));
const DocusignWrapper = lazy(() => import('../components/DocusignWrapper'));
const DocusignCompletedWrapper = lazy(() => import('../components/DocusignCompletedWrapper'));
const FADashboardWrapper = lazy(() => import('../components/FADashboard'));
const OnboardingWrapper = lazy(() => import('../components/OnboardingWrapper'));
const RetakeRTQWrapper = lazy(() => import('../components/RetakeRTQWrapper'));
const RetakeRTQCompletedWrapper = lazy(() => import('../components/RetakeRTQCompletedWrapper'));
const RceGenericDocusignComponentWrapper = lazy(() => import('../components/RceGenericDocusignWrapper'));
const BrokerageTransfer = lazy(() => import('../containers/BrokerageTransfer'));

function App(): unknown {
  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <CoreConfigProvider value={partnerConfigs}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route element={<AuthedApp authenticationOptional={false} />}>
              <Route element={<AccountProfileWrapper />} path={PageRoute.AccountProfile} />
              <Route element={<DigitalWealth />} path={PageRoute.DigitalWealth} />
              <Route element={<DocusignWrapper />} path={PageRoute.DocusignWithManagedProduct} />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.DocusignCompleteAuth} />
              <Route element={<FADashboardWrapper />} path={PageRoute.FADashboard} />
              <Route element={<OnboardingWrapper />} path={PageRoute.Onboarding} />
              <Route element={<RetakeRTQWrapper />} path={PageRoute.Questionnaire} />
              <Route element={<RetakeRTQCompletedWrapper />} path={PageRoute.RetakeRTQDocusignCompletedAuth} />
              <Route element={<RceGenericDocusignComponentWrapper />} path={PageRoute.RceDocusign} />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.RceDocusignCompletedAuth} />
              <Route element={<BrokerageTransfer />} path={PageRoute.BrokerageTransfer} />
              <Route path="/" />
            </Route>
            {/* This route is unauthenticated for Docusign to be able to redirect back without any issues */}
            {/* Since this component doesn't contain any PII or user specific details, there are no issues */}
            <Route element={<AuthedApp authenticationOptional />}>
              <Route element={<AccountProfileDocusignCompleted />} path={PageRoute.AccountProfileDocusignComplete} />
              <Route element={<AssetClassesDefinitionWrapper />} path={PageRoute.AssetClassesDefinitions} />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.DocusignComplete} />
              <Route element={<RetakeRTQCompletedWrapper />} path={PageRoute.RetakeRTQDocusignCompleted} />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.RceDocusignCompleted} />
              {/* TODO: Add page not found component */}
            </Route>
          </Routes>
        </Suspense>
      </CoreConfigProvider>
    </ThemeProvider>
  );
}
export default App;
