import { generatePath } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom-v5-compat';

export const generateAbsolutePath: typeof generatePath = (...args) => `/${generatePath(...args)}`;

export const getSearchParams = (paramValuePair?: Record<string, string | undefined>): string => {
  const paramsWithValue = Object.entries(paramValuePair ?? {})
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  return Object.keys(paramsWithValue).length ? `?${createSearchParams(paramsWithValue)}` : '';
};
