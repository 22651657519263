import {
  CUSTODIAL_IRA_ACCOUNT_TYPES,
  NON_TAX_SHELTERED_ACCOUNT_TYPES,
  TAX_SHELTERED_ACCOUNT_TYPES,
} from 'src/containers/Funding/utils';

import {
  ActiveClientColumns,
  ActiveClientFilters,
  AllocationDetailsColumns,
  AssetAllocationTableColumns,
  ContentOptions,
  CoreConfig,
  defaultCoreConfig,
  FADashboardTabs,
  FinancialAccountType,
  Locale,
  ManagedProductType,
  Partner,
  Product,
} from '@sigfig/digital-wealth-core';

import { getBaseUrl, getSymphonyPath } from '../utils';

type CoreConfigComponents = CoreConfig['components'];

export interface PartnerConfigComponents extends CoreConfigComponents {
  sfNylFunding: {
    clientRceBaseUrl: string;
  };
}

export interface PartnerConfig extends CoreConfig {
  components: PartnerConfigComponents;
  contentOptions: ContentOptions;
  defaultProductType: ManagedProductType;
}

const iqa1Url = 'https://dw-pro-iqa1-nyl.qa.sigfig.com';
const sqa1Url = 'https://dw-pro-sqa1-nyl.qa.sigfig.com';
const sqa2Url = 'https://dw-pro-sqa2-nyl.qa.sigfig.com';
const stgUrl = 'https://dw-pro-stg-nyl.qa.sigfig.com';
const prodUrl = 'https://eagleprosperportfoliosadvisor.eaglestrategies.com';

const isIqa1 = window.location.origin.toLowerCase() === iqa1Url;
const isSqa1 = window.location.origin.toLowerCase() === sqa1Url;
const isSqa2 = window.location.origin.toLowerCase() === sqa2Url;
const isStg = window.location.origin.toLowerCase() === stgUrl;
export const isProd = window.location.origin.toLowerCase() === prodUrl;

export const getConfigValue = ({
  iqa1,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }
  if (isSqa1 && sqa1) {
    return sqa1;
  }
  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

const clientRceBaseUrl = getConfigValue({
  iqa1: 'https://dw-iqa1-nyl.qa.sigfig.com',
  sqa1: 'https://dw-sqa1-nyl.qa.sigfig.com',
  sqa2: 'https://dw-sqa2-nyl.qa.sigfig.com',
  stg: 'https://dw-stg-nyl.qa.sigfig.com',
  prod: 'https://EagleProsperPortfoliosClient.EagleStrategies.com',
  _: 'https://dw-iqa1-nyl.qa.sigfig.com',
});

export const partnerConfigs: PartnerConfig = {
  contentOptions: { locale: Locale.en_us, partner: Partner.NYL, product: Product.DigitalWealthPro },
  defaultProductType: ManagedProductType.DIGITAL_ADVICE_PRO,
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    allowEditManuallyLinkedBankAccounts: true,
    allowPlaidReAuthentication: true,
    isDocusignRequiredForFinancialAccountLinkageInRCE: true,
    isManualLinkageForBankAccountSupported: true,
    isPlaidLinkageForBankAccountSupported: true,
    isSuspendedAccountStateSupported: false,
    repCodeIdentifierName: 'NYL_REP_CODE',
    showWarningForNonVerifiedFinancialAccountLinkages: true,
    showDeleteButtonOnAccountDetailPage: false,
    msiEnabled: false,
    allowDeletionForExternalBankAccounts: true,
    supportedSourceAccountTypes: [
      ...TAX_SHELTERED_ACCOUNT_TYPES,
      ...NON_TAX_SHELTERED_ACCOUNT_TYPES,
      ...CUSTODIAL_IRA_ACCOUNT_TYPES,
      FinancialAccountType.UTMA,
      FinancialAccountType.UGMA,
      FinancialAccountType.TRUST,
      FinancialAccountType.ESTATE,
      FinancialAccountType.CUSTODIAL,
    ],
    internalAccountBrokerageAliases: ['eagleProsper', 'nylife', 'envestnet'],
    journalAccountDataSourceCodes: ['SEC', 'EAG'],
  },
  components: {
    ...defaultCoreConfig.components,
    sfAccountDetails: {
      ...defaultCoreConfig.components.sfAccountDetails,
      enableViewPerformanceReport: true,
      showActualAllocationInDonut: false,
      showClosedAccountsInDropdown: true,
      showPortfolioNameInAssetAllocation: true,
    },
    sfAccountPerformance: {
      ...defaultCoreConfig.components.sfAccountPerformance,
      securityIds: ['BBG000BZZS63', 'BBG000TH7DF8'],
      showActualAllocationInDonut: false,
      showAdditionalClientsInfoForAccountType: [FinancialAccountType.JOINT],
      showFactSheetLinkInAssetAllocation: true,
      showFullAccountNumberInPdf: true,
      showPortfolioNameInAssetAllocation: true,
    },
    sfAccountProfile: {
      ...defaultCoreConfig.components.sfAccountProfile,
      showSecondaryCTA: true,
    },
    sfAccountSummary: {
      ...defaultCoreConfig.components.sfAccountSummary,
      fallBackToStagedModelPortfolio: true,
      showPendingStandingInstructionCard: true,
    },
    sfAddFunds: {
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateWeekends: true,
    },
    sfClientDocuments: {
      ...defaultCoreConfig.components.sfClientDocuments,
      downloadDocumentBaseUrl: `${getBaseUrl()}${getSymphonyPath()}`,
    },
    sfLinkBankAccount: {
      ...defaultCoreConfig.components.sfLinkBankAccount,
      showNameOnAccountField: false,
      showBankNameField: false,
      showConfirmAccountNumberField: true,
      showConfirmRoutingNumberField: true,
    },
    sfCloseAccount: {
      showEndEngagementTaxWithholdingSteps: false,
      showShouldSellAssets: true,
    },
    sfDownloadProposal: {
      allocationDetailsColumns: [
        AllocationDetailsColumns.ASSET_CLASS_LABEL,
        AllocationDetailsColumns.SECURITY_NAME,
        AllocationDetailsColumns.ALLOCATION_PERCENTAGE,
      ],
      displayAllocationBar: false,
      showModelPortfolioDescription: true,
    },
    sfDownloadQPR: {
      ...defaultCoreConfig.components.sfDownloadQPR,
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      disclosureAtBottom: false,
      displayAllocationBar: false,
      showActualAllocationInDonut: false,
    },
    sfFADashboard: {
      ...defaultCoreConfig.components.sfFADashboard,
      showActiveClientStatusFilter: false,
      hiddenActiveClientTableColumns: [
        ActiveClientColumns.PENDING_RMD_STATUS,
        ActiveClientColumns.CLIENT_REVIEW_STATUS,
      ],
      hiddenActiveClientTableFilters: [ActiveClientFilters.PENDING_RMD_STATUS],
      tabs: [FADashboardTabs.ActiveClientsTab, FADashboardTabs.ApplicationInProgressTab],
    },
    sfFunding: {
      ...defaultCoreConfig.components.sfFunding,
      allowBothFundLaterAndBankSourceTogether: true,
      allowFundingToSetFundingValue: true,
      ageDataPoint: 'data-point:da-pro-age:integer',
      amountValidationBasedOnAccountTypes: [
        {
          accountType: FinancialAccountType.TRADITIONAL_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROLLOVER_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROTH_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
      ],
      brokerageAccountNumberValidationRegex: /[^a-z0-9-]/i,
      onlyEnableLikeToLikeJournaling: true,
      isFundLaterReasonEnabled: false,
    },
    sfInvestmentRestrictions: {
      maximumRestrictions: null,
    },
    sfPaperwork: {
      ageOfTerminationAttributeName: '',
      annualIncomeLimit: 30000,
      defaultCountryCode: 'AA',
      drivingLicenseIdentifier: 'DRIVING_LICENSE',
      fetchRelatedContactsData: false,
      hiddenOptions: [
        {
          fieldName: 'data-point:identification_type:single_option',
          values: ['PASSPORT'],
          dependentOn: {
            comparisonType: 'NOT_EQUAL',
            contextVariable: 'isNonUsCitizen',
            fieldName: 'data_point:citizenship:single_option',
            value: '999',
          },
        },
      ],
      isCustomRegulatorySection: true,
      isPerStirpesEnabled: true,
      isReceivingEmailsControlledByEDeliveryEnrollment: false,
      isTrustTypeFieldsEnabled: false,
      onlyDriversIdentificationTypeForNonUsCitizen: true,
      passportIdentifier: 'PASSPORT',
      prefillAddressCountryCode: false,
      prefillIdentifierCountryForDrivingLicense: false,
      statesWithUGMA: [],
      trustedContact: {
        allowSpecialCharacters: true,
        optionalCity: true,
        optionalEmailAddress: true,
        optionalState: true,
        optionalStreetAddress: true,
        optionalZipCode: true,
      },
    },
    sfPortfolioCompare: {
      showGradeIcons: false,
    },
    sfPortfolioDetails: {
      assetAllocationTableColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.Allocation,
      ],
    },
    sfPortfolioSelection: {
      ...defaultCoreConfig.components.sfPortfolioSelection,
      assetAllocationTableColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.Allocation,
      ],
      showInvestmentAmount: false,
    },
    sfFaPlaidFlow: {
      loginBaseUrl: clientRceBaseUrl + '/plaid',
      otpExpiryTimeInMinutes: 1440,
      showProductImage: true,
    },
    sfPlan: {
      ...defaultCoreConfig.components.sfPlan,
      showPortfolioCompare: true,
      portfolioCompareAccountDetailsHoldings: {
        disableExpenseRatio: true,
      },
    },
    sfPlayback: {
      showEDisclosure: false,
      trustedContactSectionPrimary: 'beneficiary',
      trustedContactSectionSecondary: 'beneficiary',
    },
    sfWithdrawFunds: {
      disableDateAfterMaxWithdrawalDate: true,
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateTillAsSoonAsPossibleDate: true,
      disableDateWeekends: true,
      isDocusignRequiredForRetirementWithdrawals: false,
      showHardBlockForLowerLimit: false,
    },
    sfNylFunding: {
      clientRceBaseUrl,
    },
  },
};

export default {
  contentstack: {
    environment: getConfigValue({
      iqa1: 'dev',
      prod: 'nyl_prod',
      sqa1: 'nyl_sqa1',
      sqa2: 'nyl_sqa2',
      stg: 'nyl_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      iqa1: 'csaffbef3139b4b24aee2cc24c',
      prod: 'csca624325f9c1530b17380ef1',
      sqa1: 'cs77ab7edaf71c2bf8d09202c4',
      sqa2: 'cscb36302991d795f8b3efbde2',
      stg: 'csca624325f9c1530b17380ef1',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa1: 'M95ADno2d0VxTl5S5QveVfmdsXQOch3e',
      sqa1: 'snCKH9WnX3wVqBeH5oCLInOgoyDIcuqO',
      sqa2: 'M1Vnaug8vQyQt1jTlfXMoE9ATE8E4Ed1',
      stg: 'B5MwrKlHSWPaIo9ih0UrL8DKBjxLeX08',
      prod: 'YompqEaU9PR84UicsXM9P90t3W76mDq6',
      _: 'M95ADno2d0VxTl5S5QveVfmdsXQOch3e',
    }),
    domain: getConfigValue({
      iqa1: 'nyl-iqa.us.auth0.com',
      sqa1: 'nyl-sqa1.us.auth0.com',
      sqa2: 'nyl-sqa2.us.auth0.com',
      stg: 'nyl-stg.us.auth0.com',
      prod: 'nyl-prod.us.auth0.com',
      _: 'nyl-iqa.us.auth0.com',
    }),
    logoutUrl: getConfigValue({
      iqa1: iqa1Url,
      sqa1: sqa1Url,
      sqa2: sqa2Url,
      stg: stgUrl,
      prod: prodUrl,
      _: iqa1Url,
    }),
  },
  newRelic: {
    licenseKey: '3288c5f750',
    applicationId: getConfigValue({
      stg: '718348391',
      prod: '718348391',
      _: '718348393',
    }),
  },
  sentry: {
    dsn: 'https://84206b1c84814acf91f1d0faf3f67f3f@o14749.ingest.sentry.io/4505150219354112',
    environment: getConfigValue({
      iqa1: 'iqa1',
      sqa1: 'sqa1',
      stg: 'staging',
      prod: 'production',
      _: 'dev',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_wAAwlh02MecmZZxrg4fJ7BbhXCYv4HqoQxhcArpGYAO',
      _: 'phc_EpiD1VgtpRhk7u2wq303Isj8ckaylpXEFfJJOJN8BOR',
    }),
  },
};
