import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';

import { LoadingPage } from '@sigfig/digital-wealth-core';

import { RootBypass } from '../../components/RootBypass';

interface Props {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<Props> = ({ component }): any => {
  const Component = withAuthenticationRequired(RootBypass(component), {
    onRedirecting: () => <LoadingPage />,
  });
  return <Component />;
};
